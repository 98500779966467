@import "mixins";

.#{$prefix}step {
  @include step-configs;
}

// TODO: Mover o código abaixo para a view do componente
.step-sample-1 {
  height: 400px;
}

.step-sample-2 {
  height: 120px;
}

.step-sample-3 {
  height: 60px;
}

.step-sample-4 {
  display: flex;
  width: 100%;

  .col-button {
    padding-top: 20px;
    width: 46px;
  }

  .col-step {
    width: calc(100% - 94px);
  }
}
