@mixin select-configs {
  @include select-tokens;
  @include select-default;
  @include select-list;
  @include select-multiple;
}

@mixin select-tokens {
  --select-divider: 1px solid var(--color-secondary-04);
  --select-shadow: var(--surface-shadow-md);
}

@mixin select-default {
  max-width: 400px;
  min-width: 100px;
  position: relative;
}

@mixin select-list {
  .#{$prefix}list {
    background: var(--bg-color);
    box-shadow: var(--select-shadow);
    display: none;
    margin-top: -6px;
    max-height: 560px;
    overflow: auto;
    position: absolute;
    resize: none;
    width: 100%;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--color-secondary-03);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-secondary-06);
    }

    &:focus,
    &:active {
      color: var(--color-secondary-09);
      outline: none;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: var(--color-secondary-07);
      }
    }

    &[expanded] {
      display: block;
    }
  }
  .#{$prefix}item {
    --item-padding-y: var(--spacing-scale-2x);

    .content {
      padding: 0;
    }
    .#{$prefix}checkbox,
    .#{$prefix}radio {
      --item-padding-x: var(--spacing-scale-2x);
      --item-padding-y: var(--spacing-scale-2x);

      input {
        &:hover:not(:disabled) {
          + label::before {
            background-image: none;
          }
        }

        + label {
          padding-left: calc(
            var(--checkbox-padding) + var(--checkbox-size) +
              var(--item-padding-x)
          );
        }
      }
    }
    .#{$prefix}radio {
      input {
        + label {
          color: var(--text-color);
          display: block;
          height: auto;
          min-height: 0;
          padding: var(--item-padding);

          &::before,
          &::after {
            content: none;
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: var(--select-divider);
    }

    &:not([disabled]) {
      --interactive-rgb: var(--rgb-secondary-09);
      --focus-offset: calc(var(--spacing-scale-half) * -1);
      @include focus;
      @include hover;
    }

    &.selected,
    &[selected] {
      --interactive-rgb: var(--rgb-secondary-01);
      @include focus;
      @include hover;
    }

    &.disabled {
      line-height: calc(var(--font-line-height-high) * 2);
      padding-left: calc(var(--item-padding) + var(--spacing-scale-base));
    }
  }
}

@mixin select-multiple {
  .#{$prefix}item {
    &.highlighted,
    &[highlighted] {
      &:not(.selected):not([selected]) {
        background: rgba(v(rgb-support-03), 0.16);
        .#{$prefix}checkbox,
        .#{$prefix}radio {
          label {
            color: var(--color-support-03);
          }

          &:hover {
            background: linear-gradient(
              rgba(var(--interactive-rgb), var(--hover)),
              rgba(var(--interactive-rgb), var(--hover))
            );

            label {
              color: var(--text-color);
            }
          }
        }
      }
    }
  }
}
