@mixin signin-configs {
  @include signin-tokens;
  @include signin-default;
}

@mixin signin-tokens {
  --background: var(--gray-2);
  --sign-in-img: 20px;
}

@mixin signin-default {
  background-color: var(--background);
  padding: 0 var(--spacing-scale-2x);
  img {
    max-height: var(--sign-in-img);
  }
}
