// Desabilitado
@mixin disabled {
  cursor: not-allowed;
  opacity: var(--disabled);

  * {
    pointer-events: none;
  }
}

// Hover
@mixin hover {
  &:not(:disabled) {
    &:hover {
      background-image: linear-gradient(
        rgba(var(--interactive-rgb), var(--hover)),
        rgba(var(--interactive-rgb), var(--hover))
      );
    }

    &:active {
      background-image: linear-gradient(
        rgba(var(--interactive-rgb), var(--pressed)),
        rgba(var(--interactive-rgb), var(--pressed))
      );
    }
  }
}

/// Aplica foco
/// @group Reusável
/// @example scss - Uso prático
///  a {
///    @include focus;
///  }
@mixin focus {
  &:focus {
    outline: none;
  }

  &.focus-visible,
  &:focus-visible {
    outline-color: var(--focus);
    outline-offset: var(--focus-offset);
    outline-style: var(--focus-style);
    outline-width: var(--focus-width);
  }
}

/// Aplica foco tênue
/// @group Reusável
/// @example scss - Uso prático
///  input {
///    &:focus,
///    &:focus-visible,
///    &.focus-visible {
///      @include focus-soft;
///    }
///  }
@mixin focus-soft {
  border-color: var(--focus) !important;
  box-shadow: 0 0 0 var(--surface-width-md) var(--focus);
  outline: none;
}

// Ativo
@mixin active {
  background: var(--active);
  color: var(--color-secondary-01);
}

// Dark mode
@mixin dark-mode {
  --background: var(--blue-warm-vivid-90);
  --background-rgb: var(--blue-warm-vivid-90-rgb);
  --background-alternative: var(--blue-warm-vivid-90);
  --background-alternative-rgb: var(--blue-warm-vivid-90-rgb);
  --border-color: var(--pure-0);
  --color: var(--color-dark);
  --color-rgb: var(--color-dark-rgb);
  --text-color: var(--color);
  --interactive: var(--interactive-dark);
  --interactive-rgb: var(--interactive-dark-rgb);
  --visited: var(--gray-20);
  --hover: var(--surface-opacity-sm);
  --pressed: var(--surface-opacity-lg);
  --focus-color: var(--gold-vivid-20);
  --focus: var(--gold-vivid-20);
  --active: var(--pure-0);
}
@mixin light-mode {
  --background: var(--pure-0);
  --background-rgb: var(--pure-0-rgb);
  --background-alternative: var(--gray-5);
  --background-alternative-rgb: var(--gray-5-rgb);
  --border-color: var(--gray-20);
  --color: var(--color-light);
  --color-rgb: var(--color-light-rgb);
  --text-color: var(--color);
  --interactive: var(--interactive-light);
  --interactive-rgb: var(--interactive-light-rgb);
  --visited: var(--blue-warm-vivid-80);
  --hover: var(--surface-opacity-xs);
  --pressed: var(--surface-opacity-md);
  --focus-color: var(--gold-vivid-40);
  --focus: var(--gold-vivid-40);
  --active: var(--blue-warm-vivid-80);
}
