@mixin item-configs {
	@include item-tokens;
	@include item-default;
	@include item-checkbox;
	@include item-colors;
	@include item-states;
	@include item-toggle;
}

@mixin item-tokens {
	--item-background: var(--background);
	--item-color: var(--text-color);
	--item-font-weight: var(--font-weight);
	--item-padding-x: var(--spacing-scale-2x);
	--item-padding-y: var(--spacing-scale-base);
	--item-padding: var(--item-padding-y) var(--item-padding-x);
}

@mixin item-default {
	background: var(--item-background);
	border: 0;
	color: var(--item-color);
	display: block;
	font-weight: var(--item-font-weight);
	padding: var(--item-padding);
	text-align: left;
	width: 100%;
	// Regras para Grid interna
	.row {
		margin-left: calc(-1 * var(--spacing-scale-base));
		margin-right: calc(-1 * var(--spacing-scale-base));

		.col,
		[class*="col-"] {
			padding-left: v(spacing-scale-base);
			padding-right: v(spacing-scale-base);
		}
	}
	// Fix para floats internos
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}

@mixin item-checkbox {
	&[data-toggle="selection"] {
		--interactive-rgb: var(--color-rgb);
		@include hover;
	}
	.#{$prefix}checkbox,
	.#{$prefix}radio {
		margin-bottom: calc(var(--item-padding-y) * -1);
		margin-left: calc(var(--item-padding-x) * -1);
		margin-right: calc(var(--item-padding-x) * -1);
		margin-top: calc(var(--item-padding-y) * -1);

		input {
			+ label {
				padding-bottom: var(--item-padding-y);
				padding-top: var(--item-padding-y);
				top: 1px;
				width: 100%;

				&::before {
					left: var(--item-padding-x);
					top: calc(var(--item-padding-y) - 1px);
				}

				&:empty {
					left: 0;
					top: 1px;
				}
			}

			&:hover:not(:disabled) {
				+ label::before {
					background-image: none;
				}
			}
		}
	}
	.#{$prefix}checkbox {
		input {
			+ label {
				min-height: calc(
					var(--checkbox-size) + var(--item-padding-y) * 2
				);

				&:not(:empty) {
					padding-left: calc(
						var(--checkbox-padding) + var(--checkbox-size) +
							var(--item-padding-x)
					);
				}

				&::after {
					left: calc(var(--item-padding-x) + 8px);
					top: calc(var(--item-padding-y) + 4px);
				}

				&:empty {
					min-height: var(--checkbox-size);

					&::after {
						left: 8px;
						top: 4px;
					}
				}
			}
		}
	}
	.#{$prefix}radio {
		input {
			+ label {
				min-height: calc(var(--radio-size) + var(--item-padding-y) * 2);
				padding-left: calc(
					var(--radio-padding) + var(--radio-size) +
						var(--item-padding-x)
				);

				&::after {
					left: calc(
						var(--item-padding-x) + var(--radio-bullet-position)
					);
					top: calc(
						var(--item-padding-y) + var(--radio-bullet-position) -
							1px
					);
				}

				&:empty {
					min-height: var(--radio-size);

					&::after {
						left: var(--radio-bullet-position);
						top: var(--radio-bullet-position);
					}
				}
			}
		}
	}
}

@mixin item-colors {
	@each $key in map-keys($br-colors) {
		&.#{$key} {
			@include colorize($key);
		}
	}
}

@mixin item-states {
	// Selecionado
	&.selected {
		@include dark-mode;
		--item-background: var(--selected);
	}
	// Ativo
	&.active {
		@include active;
	}
}

@mixin item-toggle {
	&[data-toggle] {
		--interactive-rgb: var(--color-rgb);
		.content {
			i {
				color: var(--interactive);
			}
		}
		@include hover;
	}
}
