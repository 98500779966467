@mixin step-configs {
  @include step-tokens;
  @include step-default;
  @include step-alert;
  @include step-vertical;
  @include step-simple;
  @include step-text;
  @include step-void;
  @include step-label;
  @include step-mobile;
}

@mixin step-tokens {
  // Sizes
  --step-background: var(--bg-color);
  --step-progress-min-height: 164px;
  --step-progress-btn-max-height: 90px;
  --step-progress-btn-line-height: 32px;
  --step-progress-btn-font-size: var(--font-size-scale-up-01);
  --step-progress-btn-font-weight: v(font-weight-medium);
  --step-progress-btn-spacer: 32px;
  --step-progress-btn-size: 40px;
  --step-progress-btn-simple-size: 8px;
  --step-alert-size: 28px;
  --step-alert-icon-size: var(--icon-size);

  // Spacers
  --step-spacer: 4px;
  --step-spacer--1x: -4px;
  --step-spacer-2x: 8px;
  --step-spacer--2x: -8px;
  --step-spacer-3x: 12px;
  --step-spacer--3x: -12px;
  --step-spacer-4x: 16px;
  --step-spacer-5x: 20px;

  // Colors
  --step-color-interactive: var(--interactive);
  --step-color-transparent: transparent;
  --step-color-disabled: rgba(var(--interactive-rgb), var(--disabled));
  --step-alert-text-color: var(--color-secondary-01);
  --step-alert-color: var(--step-color-transparent);
  --step-alert-success-color: var(--color-success);
  --step-alert-warning-text-color: var(--gray-80);
  --step-alert-warning-color: var(--color-warning);
  --step-alert-info-color: var(--color-info);
  --step-alert-danger-color: var(--color-danger);
  --step-icon-background-color: var(--color-secondary-01);

  //Icons
  --step-alert-success-icon: "\f00c";
  --step-alert-warning-icon: "\f071";
  --step-alert-info-icon: "\f12a";
  --step-alert-danger-icon: "\f00d";
}

@mixin step-default {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  .step-progress {
    background-color: var(--step-background);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr)); //Padrao horizontal
    grid-template-rows: none;
    min-height: var(--step-progress-min-height);
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    .step-progress-btn {
      background-color: var(--step-color-transparent);
      border: 0;
      box-shadow: none !important;
      color: var(--step-color-interactive);
      font-size: var(--step-progress-btn-font-size);
      font-weight: var(--step-progress-btn-font-weight) !important;
      line-height: var(--step-progress-btn-line-height);
      max-height: var(--step-progress-btn-max-height);
      padding-bottom: 0;
      padding-top: 0;
      position: relative;
      text-indent: 0;
      top: calc(50% - #{var(--step-progress-btn-spacer)});
      transition-delay: 0s;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: linear;

      &[disabled] {
        color: var(--step-color-disabled);
        opacity: 1;

        &::before {
          opacity: var(--disabled);
        }
      }

      &:focus {
        outline: none;
      }

      &.focus-visible:not([disabled]):not(.disabled)::before,
      &:focus-visible:not([disabled]):not(.disabled)::before {
        outline-color: var(--focus-color);
        outline-offset: var(--focus-offset);
        outline-style: var(--focus-style);
        outline-width: var(--focus-width);
      }

      &:hover:not([disabled])::before {
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--hover)),
          rgba(var(--interactive-rgb), var(--hover))
        );
      }

      &:active:not([disabled])::before {
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--pressed)),
          rgba(var(--interactive-rgb), var(--pressed))
        );
      }

      i.step-icon {
        color: inherit;
        font-size: var(--step-alert-icon-size);
        left: 50%;
        margin-left: var(--step-spacer--3x);
        margin-top: 0;
        position: absolute;
        top: 0;
        z-index: 4;
      }

      &::before {
        background-color: var(--color-secondary-01);
        border: 1px solid var(--step-color-interactive);
        border-radius: 50%;
        box-sizing: border-box;
        color: var(--step-color-interactive);
        content: attr(step-num);
        display: block;
        font-size: var(--font-size-scale-up-03);
        font-weight: var(--step-progress-btn-font-weight) !important;
        height: var(--step-progress-btn-size);
        left: 50%;
        line-height: var(--step-progress-btn-line-height);
        margin-top: var(--step-spacer--3x);
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        transition: all 0.15s linear 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
          transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        transition: all 0.15s linear 0s,
          transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s,
          -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
        width: var(--step-progress-btn-size);
        z-index: 3;
      }

      &::after {
        background-color: v(
          color-primary-default,
          $color-primary-default
        ) !important;
        content: "";
        display: block;
        height: 1px;
        left: calc(-50% + #{var(--step-progress-btn-spacer)} / 2);
        position: absolute;
        top: var(--step-spacer);
        transition-delay: 0s;
        transition-duration: 0.15s;
        transition-property: all;
        transition-timing-function: linear;
        width: calc(100% - (#{var(--step-progress-btn-spacer)} + 3px));
        z-index: 1;
      }

      &:first-child::after {
        display: none;
      }

      &[active] {
        color: var(--active);
        font-weight: v(font-weight-semi-bold, $font-weight-semi-bold);
      }

      &[active]::after {
        background-color: var(--active);
      }

      &[active]::before {
        background-color: var(--active);
        border: 1px solid var(--color-secondary-01);
        color: var(--color-secondary-01);
      }

      &[active] i.step-icon {
        color: var(--color-secondary-01);
      }
    }
  }
}

@mixin step-alert {
  .step-progress .step-progress-btn {
    &[data-alert] {
      .step-alert {
        border: 0;
        border-radius: 50%;
        left: 50%;
        margin-left: var(--step-spacer);
        margin-top: -20px;
        position: absolute;
        top: 0;
        z-index: 5;

        &::after {
          align-items: center;
          background-color: var(--step-alert-color);
          border-radius: 50%;
          color: var(--step-alert-text-color);
          content: "";
          display: flex;
          font-family: "Font Awesome 5 Free", sans-serif;
          font-size: var(--icon-size-base);
          font-weight: var(--font-weight-black);
          height: var(--step-alert-size);
          justify-content: center;
          position: relative;
          width: var(--step-alert-size);
        }
      }
    }

    &[data-alert="success"] {
      .step-info {
        color: var(--step-alert-success-color);
      }

      .step-alert {
        background-color: var(--step-alert-success-color);

        &::after {
          content: #{var(--step-alert-success-icon)};
        }
      }
    }

    &[data-alert="warning"] {
      .step-info {
        color: var(--step-alert-warning-text-color);
      }

      .step-alert {
        background-color: var(--step-alert-warning-color);

        &::after {
          color: var(--step-alert-warning-text-color);
          content: #{var(--step-alert-warning-icon)};
        }
      }
    }

    &[data-alert="info"] {
      .step-info {
        color: var(--step-alert-info-color);
      }

      .step-alert {
        background-color: var(--step-alert-info-color);

        &::after {
          content: #{var(--step-alert-info-icon)};
        }
      }
    }

    &[data-alert="danger"] {
      .step-info {
        color: var(--step-alert-danger-color);
      }

      .step-alert {
        background-color: var(--step-alert-danger-color);

        &::after {
          content: #{var(--step-alert-danger-icon)};
        }
      }
    }
  }
}

@mixin step-simple {
  &[data-type="simple"] {
    align-items: center;
    display: flex;
    justify-content: center;

    .step-progress {
      display: flex;
      grid-template-columns: none !important;
      height: var(--step-spacer-3x);
      justify-content: center;
      overflow: hidden;
      width: fit-content;

      .step-progress-btn {
        margin: var(--step-spacer-2x) 0 var(--step-spacer);
        position: relative;
        width: var(--step-spacer-4x);

        .step-info,
        .step-icon,
        &::after,
        &[data-alert] .step-alert {
          display: none;
        }

        &::before {
          background-color: var(--color-secondary-04);
          border: 0 !important;
          content: "" !important;
          height: var(--step-progress-btn-simple-size);
          left: 50% !important;
          margin: 0 !important;
          right: unset !important;
          top: 0 !important;
          width: var(--step-progress-btn-simple-size);
        }

        &[active]:not([disabled])::before {
          background-color: var(--color-primary-darken-01);
          background-image: unset;
        }
      }

      &::after {
        display: none !important;
      }
    }
  }
}

@mixin step-text {
  &[data-type="text"] {
    align-items: center;
    display: flex;
    justify-content: center;

    .step-progress {
      display: block;
      grid-template-columns: none !important;
      height: 100%;
      overflow: hidden;
      width: 100%;

      .step-progress-btn {
        display: none;
        height: 100%;
        margin: var(--step-spacer-2x) 0 var(--step-spacer);
        position: relative;
        width: 100%;

        .step-info,
        .step-icon,
        &::after,
        &[data-alert] .step-alert {
          display: none;
        }

        &::before,
        &[active]::before,
        &.focus-visible::before,
        &:disabled::before,
        &:hover::before {
          background-color: transparent;
          background-image: none;
          border: 0;
          color: var(--color-secondary-08);
          font-size: var(--font-size-scale-base);
          font-weight: var(--step-progress-btn-font-weight);
          height: 1em;
          left: 50% !important;
          margin: 0 !important;
          right: unset !important;
          top: 0 !important;
          width: 100%;
        }

        &[active] {
          display: block;
        }
      }

      &::after {
        display: none !important;
      }
    }
  }
}

@mixin step-void {
  &[data-type="void"] {
    align-items: center;
    display: flex;
    justify-content: center;

    .step-progress {
      height: 100%;

      &::after {
        display: none !important;
      }

      .step-progress-btn {
        margin-left: var(--step-spacer--1x);
        margin-top: var(--step-spacer-3x);
        position: relative;

        .step-info,
        .step-icon {
          display: none;
        }

        &::before {
          content: "" !important;
          height: var(--step-spacer-4x);
          left: calc(50% + 3px) !important;
          margin: 0 !important;
          right: unset !important;
          top: 0 !important;
          width: var(--step-spacer-4x);
        }

        &::after {
          left: calc(-50% + 18px / 2) !important;
          margin-left: 3px !important;
          margin-top: -1px !important;
          top: var(--step-spacer-2x) !important;
          width: calc(100% - 18px) !important;
        }

        &[data-alert] .step-alert {
          border: 0;
          border-radius: 50%;
          left: 50%;
          margin-left: var(--step-spacer--2x) !important;
          margin-top: var(--step-spacer--1x) !important;

          &::after {
            border-color: transparent;
            margin-left: -1px;
            margin-top: -1px;
          }
        }

        &[active] .step-alert {
          border: var(--surface-width-lg) solid var(--blue-warm-vivid-80);
          margin-left: var(--step-spacer--3x) !important;
          margin-top: var(--step-spacer--2x) !important;
        }
      }
    }
  }
}

@mixin step-label {
  &[data-label="right"]:not(.vertical) {
    .step-progress {
      min-height: 100px;
      padding-bottom: 0;
      padding-top: v(spacing-scale-base);

      .step-progress-btn {
        padding-top: 0;
        text-align: initial;
        top: 0;

        &::before,
        i.step-icon {
          left: 54px;
          text-align: center;
          top: calc(50% - 6px);
        }

        i.step-icon {
          margin-left: var(--step-spacer--3x);
        }

        &::after {
          left: unset;
          right: calc(100% - #{var(--step-progress-btn-spacer)});
          top: 50%;
          width: calc(100% - #{var(--step-progress-btn-spacer)});
        }

        .step-info {
          background: var(--step-background);
          left: 64px;
          line-height: 1.5em;
          padding: var(--step-spacer) var(--step-spacer-4x);
          position: relative;
          text-align: left;
          top: 0;
          white-space: pre-wrap;
          z-index: 2;
        }

        .step-alert {
          left: 58px;
          margin-left: unset;
          margin-top: unset;
          position: absolute;
          top: calc(50% - 24px);
        }

        &[data-alert] {
          .step-info {
            padding-left: 18px;
          }
        }
      }
    }
  }

  &[data-label="left"]:not(.vertical) {
    .step-progress {
      min-height: 100px;
      padding-bottom: 0;
      padding-top: v(spacing-scale-base);

      .step-progress-btn {
        padding-top: 0;
        text-align: right;
        top: 0;

        &::before,
        i.step-icon {
          left: unset;
          right: 0;
          text-align: center;
          top: calc(50% - 6px);
        }

        i.step-icon {
          margin-right: var(--step-alert-size);
        }

        &::after {
          left: unset;
          right: 60px;
          top: 50%;
          width: calc(100% - #{var(--step-progress-btn-spacer)});
        }

        .step-info {
          background: var(--step-background);
          left: unset;
          line-height: 1.5em;
          padding: var(--step-spacer) var(--step-spacer-4x);
          position: relative;
          right: 48px;
          text-align: right;
          top: 0;
          white-space: pre-wrap;
          z-index: 2;
        }

        .step-alert {
          left: unset;
          margin-left: unset;
          margin-top: unset;
          position: absolute;
          right: 6px;
          top: calc(50% - 24px);
        }
      }
    }
  }

  &[data-label="top"] {
    .step-progress {
      padding-bottom: v(spacing-scale-2x);
      padding-top: 0;

      .step-progress-btn {
        padding-top: 0;
        top: 0;

        &::before {
          top: calc(100% - #{var(--step-spacer-3x)});
        }

        i.step-icon {
          margin-left: var(--step-spacer--3x);
          top: calc(100% - #{var(--step-spacer-3x)});
          //top: 50%;
        }

        &::after {
          top: calc(100% - #{var(--step-spacer-2x)});
        }

        .step-alert {
          left: calc(50% + 6px);
          margin-left: unset;
          margin-top: unset;
          position: absolute;
          top: calc(100% - #{var(--step-progress-btn-spacer)});
        }
      }
    }
  }
}

@mixin step-vertical {
  &.vertical {
    &[data-label="right"],
    & {
      .step-progress {
        float: left;
        grid-template-columns: 1fr; //Padrão vertical
        height: 100%;
        max-width: 260px;
        overflow-x: hidden;
        padding-top: 0;
        position: relative;
        text-align: right;

        .step-progress-btn {
          height: 100%;
          line-height: 100%;
          max-height: 100%;
          padding-bottom: 0;
          padding-left: 60px;
          padding-right: var(--step-spacer-2x);
          padding-top: 0;
          text-align: left;

          &::before {
            left: var(--step-progress-btn-spacer);
            line-height: var(--step-progress-btn-line-height);
            margin-top: calc(#{v(spacing-scale-2x)} * -1);
            position: absolute;
            text-align: center;
            top: 50%;
          }

          &::after {
            height: calc(100% - #{var(--step-progress-btn-spacer)});
            left: var(--step-progress-btn-spacer);
            position: absolute;
            top: calc(-50% + #{var(--step-progress-btn-size)} / 2);
            width: 1px;
          }

          &[active]::after {
            width: 1px;
          }
        }
      }
    }

    &[data-label="left"] {
      .step-progress-btn {
        padding-bottom: 0;
        padding-left: var(--step-spacer-2x);
        padding-right: 70px;
        text-align: right;

        &::before {
          left: calc(100% - #{var(--step-progress-btn-spacer)});
        }

        &::after {
          left: calc(100% - #{var(--step-progress-btn-spacer)});
        }
      }
    }

    &[data-scroll] {
      .step-progress {
        overflow-x: hidden;
        overflow-y: auto;

        .step-progress-btn {
          min-height: 100px;
        }
      }
    }

    .step-form {
      float: left;
      height: 100%;
      width: calc(100% - 260px);

      .step-panel {
        .step-panel-content {
          border-top: 0;
        }
      }
    }
  }
}

@mixin step-mobile {
  @include media-breakpoint-down(sm) {
    .step-progress {
      max-height: 100px;
      min-height: 100px;
      overflow-y: hidden;
      padding-top: v(spacing-scale-3x);
      position: relative;
      transition-delay: 0s;
      transition-duration: 0.25s;
      transition-property: all;
      transition-timing-function: linear;

      .step-progress-btn {
        margin-top: 0;

        .step-info {
          font-size: v(font-size-scale-down-02, $font-size-scale-down-02);
          line-height: var(--step-spacer-3x) !important;
          max-width: 70%;
          overflow: hidden;
          padding: 0;
          text-align: center;
          // text-overflow: ellipsis;
          // white-space: nowrap !important;
        }

        &[data-alert] .step-alert::after {
          font-size: 16px;
          height: var(--step-alert-size);
          width: var(--step-alert-size);
        }
      }
    }

    &[data-label="right"]:not(.vertical) {
      .step-progress {
        min-height: 68px;

        .step-progress-btn {
          max-height: 54px;
          right: inherit;

          .step-info {
            //margin: 0 16px;
            padding: 0 var(--step-spacer-4x);
            text-align: right !important;
            top: var(--step-spacer--1x);
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
        }
      }
    }

    &[data-label="left"]:not(.vertical) {
      .step-progress {
        min-height: 68px;

        .step-progress-btn {
          max-height: 54px;
          right: inherit;

          .step-info {
            //padding: 0 16px;
            padding: 0var (--step-spacer-2x);
            text-align: left !important;
            top: var(--step-spacer--1x);
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
        }
      }
    }

    &[data-label="top"] {
      .step-progress {
        padding-bottom: v(spacing-scale-2x);
        padding-top: 0;

        .step-progress-btn {
          margin-top: -20px;
          padding: 0;

          &::before {
            bottom: var(--step-spacer--1x);
            top: unset;
          }

          &::after {
            bottom: var(--step-spacer-2x);
            top: unset;
          }

          .step-info {
            margin-top: var(--step-spacer--1x);
            position: relative;
          }

          i.step-icon {
            margin-left: var(--step-spacer--3x);
            top: calc(100% - var(--step-spacer-5x));
          }
        }
      }
    }

    &[data-type="void"] {
      .step-progress-btn {
        height: min-content;
        margin-bottom: var(--step-spacer-3x);
        position: relative;
      }
    }

    &.vertical {
      &[data-label="right"],
      & {
        .step-progress {
          max-height: 100%;
          max-width: 110px;

          &::after {
            height: 1em;
            left: unset;
            margin-top: -0.5em;
            right: 2px;
            top: 50%;
            transform: rotate(-90deg);
            width: 1em;
          }

          .step-progress-btn {
            line-height: 18px;
            padding-left: 46px;
            padding-right: var(--step-spacer-3x);
            top: 0;

            &::before {
              left: 24px;
            }

            &::after {
              left: 24px;
            }
          }
        }
      }

      &[data-label="left"] {
        .step-progress-btn {
          padding-right: v(spacing-scale-7x);

          &::before {
            left: unset;
            right: var(--step-spacer--2x);
          }

          &::after {
            left: unset;
            right: 31px;
          }

          .step-info {
            line-height: 1.6em;
            margin-top: -1.3em;
            position: absolute;
            right: 54px;
            text-align: right;
          }
        }
      }
    }
  }
}
