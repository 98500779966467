@mixin icon-size($value) {
  @include icon {
    --icon-size: #{$value};
  }
}

@mixin icon {
  .svg-inline--fa,
  .fa,
  .fab,
  .fad,
  .fal,
  .far,
  .fas {
    @content;
  }
}
