@import "mixins";

.#{$prefix}item {
  @include item-configs;
}

// Item aplicado em link/botão
a.#{$prefix}item,
button.#{$prefix}item {
  --interactive-rgb: var(--rgb-primary-default);
  --item-color: var(--interactive);
  --focus-offset: calc(var(--spacing-scale-half) * -1);
  @include hover;
}
