@mixin radio-configs {
  @include radio-tokens;
  @include radio-default;
  @include radio-label;
  @include radio-checked;
  @include radio-colors;
  @include radio-sizes;
  @include radio-states;
}

@mixin radio-tokens {
  --radio-background: var(--color-secondary-01);
  --radio-font-weight: var(--font-weight-medium);
  --radio-size: 24px;
  --radio-bullet-size: 16px;
  --radio-bullet-position: calc(
    (var(--radio-size) - var(--radio-bullet-size)) * 0.5
  );
  --radio-padding: var(--spacing-scale-base);
}
@mixin radio-default {
  display: block;

  & + & {
    margin-top: var(--spacing-scale-base);
  }
  // Remover o checkbox padrão do navegador
  input {
    opacity: 0;
    position: absolute;

    + label {
      cursor: pointer;
      display: inline-block;
      min-height: var(--radio-size);
      padding-left: calc(var(--radio-padding) + var(--radio-size));
      position: relative;

      &::before {
        background: var(--radio-background);
        border: 1px solid v(color-secondary-04);
        border-radius: 50%;
        content: "";
        height: var(--radio-size);
        left: 0;
        position: absolute;
        top: -1px;
        width: var(--radio-size);
      }

      &:empty {
        min-height: 20px;
        padding: 0;

        &::before {
          top: 0;
        }
      }
    }
  }
}
@mixin radio-label {
  label {
    color: var(--text-color);
    font-size: var(--font-size);
    font-weight: var(--radio-font-weight);
  }
}
@mixin radio-checked {
  input {
    &:checked + label {
      &::after {
        background: var(--selected);
        border: 7px solid var(--selected);
        border-radius: 50%;
        content: "";
        height: var(--radio-bullet-size);
        left: var(--radio-bullet-position);
        position: absolute;
        right: 0;
        top: calc(var(--radio-bullet-position) - 1px);
        width: var(--radio-bullet-size);
      }

      &:empty {
        &::after {
          top: var(--radio-bullet-position);
        }
      }
    }
  }
}
@mixin radio-states {
  input {
    // Inválid
    &:invalid {
      + label::before {
        border-color: var(--color-danger);
      }
    }
    //Focus
    &:focus-visible,
    &.focus-visible {
      &:checked + label::before,
      + label::before {
        @include focus-soft;
      }
    }
    //hover
    &:hover:not(:disabled) {
      + label::before {
        background-image: linear-gradient(
          rgba(var(--interactive-rgb), var(--hover)),
          rgba(var(--interactive-rgb), var(--hover))
        );
      }
    }
    // Disabled
    &:disabled + label {
      @include disabled;
    }
  }
}
@mixin radio-colors {
  @each $state, $color in (valid, success), (invalid, danger) {
    &.is-#{$state},
    &.#{$state},
    &[#{$state}] {
      input {
        + label::before {
          border-color: var(--color-#{$color});
        }
      }
    }
  }
}
@mixin radio-sizes {
  // Versão pequena
  &.is-small,
  &.small,
  &[small] {
    input {
      // Texto
      + label {
        line-height: var(--spacing-scale-2xh);
        min-height: var(--spacing-scale-2xh);
      }
      // Caixa
      + label::before {
        height: var(--spacing-scale-2xh);
        width: var(--spacing-scale-2xh);
      }
      // Checado
      &:checked + label::after {
        border-width: 4px;
        height: 10px;
        width: 10px;
      }
    }
  }
}
