@mixin magic-configs {
  @include magic-tokens;
  @include magic-default;
  @include magic-button;
  @include magic-sizes;
}

@mixin magic-tokens {
  --magic-size: var(--magic-medium);
  --magic-small: var(--spacing-scale-4xh);
  --magic-medium: var(--spacing-scale-5xh);
  --magic-large: var(--spacing-scale-6xh);
  --magic-support-size: var(--magic-support-medium);
  --magic-support-small: var(--spacing-scale-7x);
  --magic-support-medium: var(--spacing-scale-8x);
  --magic-support-large: var(--spacing-scale-9x);
  --magic-z-index: var(--z-index-layer-1);
}

@mixin magic-default {
  display: inline-flex;
  position: relative;
}

@mixin magic-button {
  .#{$prefix}button {
    --button-size: var(--magic-support-size);
    background-color: var(--green-cool-vivid-50);
    border: var(--gray-5) solid
      calc((var(--magic-support-size) - var(--magic-size)) * 0.5);
    box-shadow: var(--surface-shadow-md);
    color: var(--background);
    font-size: var(--font-size-scale-up-02);
    z-index: var(--magic-z-index);
  }
}

@mixin magic-sizes {
  @each $size in small, medium, large {
    &.#{$size} {
      --magic-size: var(--magic-#{$size});
      --magic-support-size: var(--magic-support-#{$size});
    }
  }
}
