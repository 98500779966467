@import "mixins";

.#{$prefix}wizard {
  @include wizard;
}

// TODO: Mover o código abaixo para a view do componente
.wizard-sample-1 {
  height: 400px;
}

.wizard-sample-2 {
  height: 400px;
}
